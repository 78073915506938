var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('S2SCard',{attrs:{"title":"Pending Invites"}},[_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.toggle_id),callback:function ($$v) {_vm.toggle_id=$$v},expression:"toggle_id"}},[_c('v-container',[_c('v-row',_vm._l((_vm.connectionTypes),function(status,index){return _c('v-flex',{key:index,attrs:{"xs12":"","sm3":"","px-2":"","py-2":""}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":active ? 'primary' : '',"dark":"","height":"50"},on:{"click":toggle}},[_c('v-scroll-y-transition',[(active)?_c('div',{staticClass:"display-5 flex-grow-1 text-center"},[_vm._v(_vm._s(status.name))]):(!active)?_c('div',{staticClass:"display-5 flex-grow-1 text-center"},[_vm._v(_vm._s(status.name))]):_vm._e()])],1)]}}],null,true)})],1)}),1)],1)],1),_c('v-data-table',{ref:"dataTable",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filterPendingConnections(_vm.toggle_id),"loading":_vm.loading,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.onApprove(item.id)}}},on),[_c('v-icon',[_vm._v("check")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.onDecline(item.id)}}},on),[_c('v-icon',[_vm._v("close")])],1)]}}],null,true)},[_c('span',[_vm._v("Decline")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }