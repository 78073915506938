<template>
	<S2SCard title="Pending Invites">
		<v-item-group v-model="toggle_id" mandatory>
			<v-container>
				<v-row>
					<v-flex xs12 sm3 v-for="(status, index) in connectionTypes" :key="index" px-2 py-2>
						<v-item v-slot:default="{ active, toggle }">
							<v-card :color="active ? 'primary' : ''" class="d-flex align-center" dark height="50" @click="toggle">
								<v-scroll-y-transition>
									<div v-if="active" class="display-5 flex-grow-1 text-center">{{ status.name }}</div>
									<div v-else-if="!active" class="display-5 flex-grow-1 text-center">{{ status.name }}</div>
								</v-scroll-y-transition>
							</v-card>
						</v-item>
					</v-flex>
				</v-row>
			</v-container>
		</v-item-group>

		<v-data-table
			ref="dataTable"
			:headers="headers"
			:items="filterPendingConnections(toggle_id)"
			:loading="loading"
			:options.sync="pagination"
			class="elevation-1"
		>
			<template v-slot:item.remove="{ item }">
				<div>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn icon color="green" @click="onApprove(item.id)" v-on="on">
								<v-icon>check</v-icon>
							</v-btn>
						</template>
						<span>Approve</span>
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn icon color="error" @click="onDecline(item.id)" v-on="on">
								<v-icon>close</v-icon>
							</v-btn>
						</template>
						<span>Decline</span>
					</v-tooltip>
				</div>
			</template>
		</v-data-table>
	</S2SCard>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "PendingConnections",

	computed: {
		loading: function() {
			return this.$store.state.organisations.loading;
		},
		connectionTypes: function() {
			return this.$store.getters["organisations/connections/connectionTypes"];
		},
		filterPendingConnections: function() {
			return this.$store.getters["organisations/connections/filterPendingConnections"];
		}
	},

	data: function() {
		return {
			editModel: {},
			toggle_id: 0,
			headers: [
				{ text: "Customer Code", value: "customerCode" },
				{ text: "Customer Name", value: "customerName" },
				{ text: "Types", value: "types" },
				{ text: "Contact Name", value: "contactName" },
				{ text: "Contact Number", value: "contactNumber" },
				{ text: "Status", value: "status" },
				{ text: "Approve Customer", value: "remove", type: "action", align: "center" }
			],
			pagination: {
				page: 1,
				itemsPerPage: 10 // -1 for All
			}
		};
	},

	mounted: function() {
		this.$store.dispatch("organisations/connections/fetchConnectionTypes");
		this.$store.dispatch("organisations/connections/fetchConnectionsPending", { status: "PENDING", showDisabled: false });
	},

	methods: {
		async onApprove(connectionId) {
			const success = await this.$store.dispatch("organisations/connections/acceptConnection", connectionId);
			if (success) this.$store.dispatch("organisations/connections/fetchConnectionsPending", { status: "PENDING", showDisabled: false });
		},
		async onDecline(connectionId) {
			const success = await this.$store.dispatch("organisations/connections/declineConnection", connectionId);
			if (success) this.$store.dispatch("organisations/connections/fetchConnectionsPending", { status: "PENDING", showDisabled: false });
		}
	}
});
</script>
